import config from '@/config';
const html = (appInfo) => {
  return `<div>
<div class="text-primary mb10">应用名称：${appInfo.name}</div>
<p>福州${config.appTitle}科技有限公司（以下称为本公司）尊重保护所用户的个人隐私权。为了给您提供更准确、更有个性化的服务,本公司会按照本隐私权政策的规定使用和披露您的个人信息。但本公司将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外,在未征得您事先许可的情况下,本公司不会将这些信息对外披露或向第三方提供。本公司会不时更新本隐私权政策。您在同意本公司服务使用协议之时,即视为您已经同意本隐私政策全部内容。本隐私权政策属于本公司服务使用协议不可分割的一部分。</p></div>
	<p class="fs16 mtb10">我们收集信息目的</p>
	<p>我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。</p>
	<p>· 您在使用我们服务时主动提供的信息</p>
	<p>· 您在注册帐户时填写的信息您在使用服务时上传的信息</p>
	<p>· 您通过我们的客服或参加我们举办的活动时所提交的信息</p>
	<p>· 日志信息。当您使用我们的服务时，我们可能会自动收集相关信息井存储为服务日志信息。</p>
	<p>1）设备信息(含IMEI等)</p>
	<p>例如，设备型号、操作系统版本、唯一设备标识符（IMEI等）、MAC地址 、电池、信号强度等信息。 </p>
	<p>2）软件信息</p>
	<p>例如，软件的版本号、浏览器类型。为确保操作环境的安全或提供报务所需，我们会收集有关您使用的移动应用和其他软件的信息。 </p>
	<p>3）IP地址 </p>
	<p>4）服务日志信息</p>
	<p>例如，您在使用我们服务时搜索、查看的信息、服务故障信息、引荐网址等信息。 </p>
	<p>5）通讯日志信息</p>
	<p>例如，您在使用我们服务时曾经通讯的账户，通讯时间和时长。</p>
	<p>6） 录音权限;当您使用跟读功能时，我们将获取录音权限。</p>
	<p>7） 电话权限：便于您在应用内拨打电话联系客服。</p>
	<p class="fs16 mtb10">其他相关信息</p>
	<p>为了帮助您更好地使用我们的产品或服务，我们会收集相关信息。例如，其他用户分享的信息中含有您的信息</p>
	<p>收集方法</p>
	<p>我们通过您使用该软件询问方式获取您的授权同意方可获取您的信息</p>
	<p>我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途传用您的信息，我们将再次向您进行说明，并征得您的同意。</p>
	<p>向您提供服务满足您的个性化需求</p>
	<p>例如，语言设定、位置设定、个性化的帮助服务。</p>
	<p>我们如何使用Cookie、标识符及相关技术</p>
	<p>我们或我们的第三方合作伙伴，可能通过放置安全的 Cookie、标识符及相关技术收集您的信息，以便为您提供更个性化的用户体验和服务。我们会严格要求第三方合作伙伴遵守本政策的相关规定。</p>
	<p>您也可以通过浏览器设置管理Cookie。但请注意，如果停用Cookie，您可能无法享受最佳的服务体验，某些服务也可能无法正常使用。若您希望了解更多Cookie的安全性等信息，可参见《Cookie政策说明》。</p>
	<p class="fs16 mtb10">您分享的信息</p>
	<p>通过我们的服务与您的好友、家人及其他用户分享您的相|关信息。例如，您在微信朋友圈中公开分享的文字和照片。</p>
	<p>存储信息的地点和期限</p>
	<p>1）我们遵守法律法规的规定。将境内收集的用户个人信息存储于境内（当前存放于中国大陆阿里云服务器）。</p>
	<p>2）一般而言。我们仅为实现目的所必需的最短时间保留您的个人信息。但在下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：</p>
	<p>·为遵守适用的法律法规等有关规定;</p>
	<p>·为遵守法院判决、裁定或其他法律程序的规定;</p>
	<p>·为遵守相关政府机关或法定授权组织的要求;</p>
	<p>·我们有理由确信需要遵守法律法规等有关规定;</p>
	<p>当我们的产品或服务发生停止运营的情形时，我们将采取例如，推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。</p>
	<p class="fs16 mtb10">适用范围</p>
	<p>我们的所有服务均适用本政策。</p>
	<p>某些服务有其特定的隐私指引/声明，该特定隐私指引/声明更具体地说明我们在该服务中如何处理您的信息。如本政策与特定服务的隐私指引/声明有不一致之处，请以该特定隐私指引声明为准。</p>
	<p>请您注意，本政策不适用由其他公司或个人提供的服务。您使用该等第三方服务须受基隐私政策（而非本政策）约束，您需要仔细阅读其政策内容。</p>
	<p class="fs16 mtb10">第三方SDK使用方式目的范围</p>
	<p>微信授权登录SDK服务，该服务用来支持用户快捷登录。获取到用户微信昵称，地址。</p>
	<p>微信支付SDK服务，该服务用来支持付费购买。获取到用户的IP。</p>
	<div class="black">账号注销说明</div>
	<p>如果你已经不需要我们的服务了，可以在个人中心->账号注销，执行账户注销操作，账号注销后，我们后台即刻删除相应账号信息（含账号，密码，设备信息，IP地址等），你将无法再次登录使用此账号。</p>
	<p class="tr">更新时间:2021年8月20日</p>
</div>`;
};
export default html;